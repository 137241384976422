import React, { useContext } from 'react'
import InfoLine from '../../../components/info-line/info-line'
import EditIcon from '../../../components/icons/edit'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../user-context'
import { RemotizeType } from '../signup/signup'

const WanMode = {
    DHCP: 0,
    STATIC: 1,
    PPPoE: 2,
    BRIDGE: 3,
    BRIDGE_PPPOE: 4
}
const Bandwidth =  ["20MHz", "20/40MHz", "20/40/80MHz"]

export function getWanModeString(mode){
    switch(mode){

        case WanMode.DHCP:
            return 'DHCP'
        case WanMode.STATIC:
            return 'Estático'
        case WanMode.PPPoE:
            return 'PPPoE'
        case WanMode.BRIDGE:
            return 'Bridge'
        case WanMode.BRIDGE_PPPOE:
            return 'Bridge_PPPoE'
        default:
            return 'invalid'
    }
}

function composeInfoLine(label, value){
    return <InfoLine key={`${label}-${value}`} label={label} value={value}></InfoLine>
}

function getWanDhcp(profile, elements){

    elements.push(composeInfoLine('MTU', profile.wan.mtu))
    elements.push(composeInfoLine('DNS automático', profile.wan.dns_v4.automatic? 'Sim' : 'Não'))

    if (!profile.wan?.dns_v4.automatic) {
        elements.push(composeInfoLine('DNS1', profile.wan.dns_v4.dns1))
        elements.push(composeInfoLine('DNS2', profile.wan.dns_v4.dns2 ))
        elements.push(composeInfoLine('DNS3', profile.wan.dns_v4.dns3 ))
    }

    return elements
}

function getWanStatic(profile, elements){

    elements.push(composeInfoLine('Endereço IP', profile.wan.static.ip))
    elements.push(composeInfoLine('Máscara de sub-rede', profile.wan.static.netmask))
    elements.push(composeInfoLine('Gateway', profile.wan.static.gateway))
    elements.push(composeInfoLine('DNS1', profile.wan.static.dns1))
    elements.push(composeInfoLine('DNS2', profile.wan.static.dns2))
    elements.push(composeInfoLine('DNS3', profile.wan.static.dns3))
    elements.push(composeInfoLine('MTU', profile.wan.mtu))

    return elements
}

function getWanPPPoE(profile, elements){

    elements.push(composeInfoLine('Usuário', profile.wan.pppoe.username))
    elements.push(composeInfoLine('Senha', profile.wan.pppoe.password))
    elements.push(composeInfoLine('MTU', profile.wan.pppoe.mtu))
    elements.push(composeInfoLine('DNS automático', profile.wan.dns_v4.automatic? 'Sim' : 'Não'))
   if (!profile.wan?.dns_v4.automatic) {
    elements.push(composeInfoLine('DNS1', profile.wan.dns_v4.dns1))
    elements.push(composeInfoLine('DNS2', profile.wan.dns_v4.dns2 ))
    elements.push(composeInfoLine('DNS3', profile.wan.dns_v4.dns3 ))
   }



    return elements
}

function getGeneralInfo(profile){

    let elements = []

    elements.push(
        composeInfoLine('Perfil padrão', profile.isDefault ? 'Sim' : 'Não')
    )

    // elements.push(
    //     composeInfoLine('Aplicação automática', profile.autoApply ? 'Sim' : 'Não')
    // )

    elements.push(
        composeInfoLine('Tempo de reset', `${profile.factoryDefault.pressedTime} segundos`)
    )


    return elements 
}

function getWanInfo(profile){

    let elements = []

    elements.push(
        composeInfoLine('Modo de operação', getWanModeString(profile.wan.mode))
    )

    switch(profile.wan.mode){
        case WanMode.DHCP:
            return getWanDhcp(profile, elements)
        case WanMode.STATIC:
            return getWanStatic(profile, elements)
        case WanMode.PPPoE:
            return getWanPPPoE(profile, elements)
        case WanMode.BRIDGE:
            return getWanDhcp(profile, elements)
        case WanMode.BRIDGE_PPPOE:
            return getWanDhcp(profile, elements)
        default:
            return ''
    }
}

function getLanInfo(profile){
    let elements = []
    
    elements.push(
        composeInfoLine('Endereço IP', profile.lan.ip)
    )

    elements.push(
        composeInfoLine('Máscara de sub-rede', profile.lan.netmask)
    )

    return elements
}

function getWirelessInfo(profile){
    let elements = []
        elements.push(
            composeInfoLine('Dual Band', profile.wireless.enableDualBand ? "Sim" : "Não")
        )

    if (profile.wireless.useCompatibilityMode) {
        if(profile.wireless.enableDualBand ){
            elements.push(
                composeInfoLine('SSID', profile.wireless.freq_2_4G.ssid)
            )
        }else {
            elements.push(
                composeInfoLine('SSID 2.4 GHz', profile.wireless.freq_2_4G.ssid)
            )
            elements.push(
                composeInfoLine('SSID 5 GHz', profile.wireless.freq_5G.ssid)
            )
        }
    } else {
        elements.push(
            composeInfoLine('SSID', profile.wireless.ssid)
        )  
    }
 
    if (profile.radio.enableConfigureRadio){
        elements.push(
            composeInfoLine('Radio Personalizado',  "Sim")
        )
     
        elements.push(
            composeInfoLine('Canal 2.4 GHz', profile.radio.freq_2_4G.channel === 0 ? "Automático" : profile.radio.freq_2_4G.channel)
        )
        elements.push(
            composeInfoLine('Larg. de banda 2.4 GHz', Bandwidth[profile.radio.freq_2_4G.bandwidth])
        )

        elements.push(
            composeInfoLine('Canal 5 GHz', profile.radio.freq_5G.channel === 0 ? "Automático" : profile.radio.freq_5G.channel)
        )
        elements.push(
            composeInfoLine('Larg. de banda 5 GHz', Bandwidth[profile.radio.freq_5G.bandwidth])
        )

  


    }

    return elements
}

function getRemoteWebAccessInfo(profile){
    let elements = []

    elements.push(
        composeInfoLine('Acesso Remoto (HTTPS)', profile.remoteWebAccess.https_enable ? 'Habilitado' : 'Desabilitado')
    )
    if (profile.remoteWebAccess.https_enable) {
        elements.push(
            composeInfoLine('Porta', profile.remoteWebAccess.https_port)
        )
    }
    elements.push(
        composeInfoLine('Acesso Remoto (HTTP)', profile.remoteWebAccess.enabled ? 'Habilitado' : 'Desabilitado')
    )

    if(profile.remoteWebAccess.enabled){
        elements.push(
            composeInfoLine('Porta', profile.remoteWebAccess.port)
        )
    }

    elements.push(
        composeInfoLine('Ping via WAN', profile.remoteWebAccess.pingWan ? 'Habilitado' : 'Desabilitado')
    )

    elements.push(
        composeInfoLine('Acesso via IPv6', profile.remoteWebAccess.ipv6Enabled ? 'Habilitado' : 'Desabilitado')
    )

    return elements
}

function getRouterAccessInfo(profile){

    if(!profile.routerAccess.username){
        profile.routerAccess = {username:'admin', password: 'admin'}
    }

    return[
        composeInfoLine('Usuário', profile.routerAccess.username),
        composeInfoLine('Senha', profile.routerAccess.password),
    ]
}

function getDhcpServerInfo({dhcp}) {

    let elements = []

    elements.push((
        composeInfoLine('Habilitado', dhcp.enabled ? 'Sim' : 'Não')
    ))

    return [...elements,
        composeInfoLine('Endereço inicial', dhcp.rangeStart),
        composeInfoLine('Endereço final', dhcp.rangeEnd),
        composeInfoLine('Tempo de Lease',  `${dhcp.dhcpLeaseTime} minutos`),
        composeInfoLine('DNS 1', dhcp.dns1),
        composeInfoLine('DNS 2', dhcp.dns2),
    ]
}

function getMesh(profile) {

    let elements = []

    elements.push((
        composeInfoLine('Habilitado', profile.mesh.enabled ? 'Sim' : 'Não')
    ))

    return [...elements,
        composeInfoLine('Tipo', profile.mesh.type ?   'Nó Secundário': 'Nó Principal')
    ]
}

function getTr069({ tr069 }) {
    let elements = [];
    if (tr069.tr069_enable) {
        elements = [...elements,
        composeInfoLine('URL ACS', tr069.acs.acs_url),
        composeInfoLine('Usuário do ACS', tr069.acs.acs_username),
        composeInfoLine('Senha do ACS', tr069.acs.acs_password),
        ]
        if (tr069.acs.tr069_inform)
            elements.push((composeInfoLine('Intervalo de notific. prog. ', tr069.acs.tr069_interval)));

        if (tr069.connection.enabled)
            elements = [...elements,
            composeInfoLine('Usuário da solicit. de con. ', tr069.connection.conreq_name),
            composeInfoLine('Senha da solicit. de con. ', tr069.connection.conreq_pw),
            composeInfoLine('Porta da solicit. de con. ', tr069.connection.conreq_path),
            composeInfoLine('End. da solicit. de con. ', tr069.connection.conreq_port),
            ]

        if (tr069.stun.enabled)
            elements = [...elements,
            composeInfoLine('End. de Serv. STUN', tr069.stun.stun_server_addr),
            composeInfoLine('Porta do Serv. STUN', tr069.stun.stun_server_port),
            ]
    }
    else
        elements.push((
            composeInfoLine('Habilitado', "Não")));

    return elements;
}

export default function ProfileViewer({profile}) {
    
    const history = useHistory()
    const [user,] = useContext(UserContext)

    const confirmEdition = (segment) => {
        history.push({pathname: "/profiles/confirm", state:{profile: profile, segment:segment}})
    }

    const editButton = (segment) => {

        if(profile.name === 'Intelbras' || user.type !== RemotizeType.ADMIN) return null

        return(
        <div className='profile-section-edit-button' onClick={() => confirmEdition(segment)}>
            <EditIcon size={15}></EditIcon>
        </div>)
    }

    return <React.Fragment>

        <div className='profile-viewer-title'>{profile.name}{profile.isDefault ? ' (Padrão)' : ''}</div>

        <div className='profile-viewer'>
        <section>

            {editButton('name')}

            <div className='profile-section-title'>Geral</div>

            {getGeneralInfo(profile)}

        </section>

        <section>

            {editButton('wan')}

            <div className='profile-section-title'>WAN</div>

            {getWanInfo(profile)}

        </section>

        <section>

            {editButton('lan')}

            <div className='profile-section-title'>LAN</div>

            {getLanInfo(profile)}

        </section>

        <section>

            {editButton('dhcp')}

            <div className='profile-section-title'>Servidor DHCP</div>

            {getDhcpServerInfo(profile)}

        </section>

        <section>

            {editButton('wifi')}

            <div className='profile-section-title'>Wi-Fi</div>

            {getWirelessInfo(profile)}

        </section>

        <section>

            {editButton('remote')}

            <div className='profile-section-title'>Gerenciamento Remoto</div>

            {getRemoteWebAccessInfo(profile)}

        </section>

        <section>

            {editButton('router')}

            <div className='profile-section-title'>Acesso ao Roteador</div>

            {getRouterAccessInfo(profile)}

        </section>

        <section>

        {editButton('mesh')}

        <div className='profile-section-title'>InMesh</div>

        {getMesh(profile)}

        </section>

        <section>

        {editButton('tr069')}

        <div className='profile-section-title'>TR-069</div>

        {getTr069(profile)}

        </section>
        
        </div>
    </React.Fragment>
}